<template>
  <UIModal
    :is-open="dialog"
    :title="title"
    :type="ModalType.DIALOG"
    :uncloseable="isLoading"
    @hide="handleDialogClose"
  >
    <div class="mb-2">
      <slot v-if="isLoading" name="loading"
        >Importing and validating. This can take a few minutes</slot
      >
      <slot v-else name="info">
        You can use a spreadsheet to import new items. The spreadsheet file must
        be CSV.
      </slot>
      <slot v-if="hasExample && !isLoading" name="example"
        >An example format is available via the link below.</slot
      >
    </div>
    <div class="text-center">
      <UIButton
        v-if="hasExample && !isLoading"
        label="Download template"
        :leading="RectangleGroupIcon"
        variant="secondary"
        fill="light"
        @click="downloadTemplate"
      />
      <div class="mt-6 flex justify-center space-x-2">
        <UIButtonClose :disabled="isLoading" @click="dialog = false" />
        <UIButtonSave
          label="Select a file"
          type="button"
          :leading="DocumentArrowDownIcon"
          :disabled="isLoading"
          :loading="isLoading"
          @click="processFile"
        />
      </div>
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { parse } from 'papaparse'
import { snakeCase } from 'lodash'

import { GridColumn, ModalType } from '@types'
import { ImportDialogItem, ImportDialogStep } from './utils/types'
import { validateHeadings } from './utils/business-logic'

import { CSV_SEPARATOR } from '@/const/common'
import { useNotifications } from '@/plugins/notification'

import {
  RectangleGroupIcon,
  DocumentArrowDownIcon,
} from '@heroicons/vue/24/outline'
import { UIModal } from '@ui/modals'
import { UIButton, UIButtonClose, UIButtonSave } from '@ui/buttons'

type Props = {
  title: string
  columns?: GridColumn[]
  headersExample?: string
  example?: string
}

type Emits = {
  'import:start': []
  'import:step': [data: ImportDialogStep]
  'import:complete': [data: ImportDialogItem[]]
}

const { error } = useNotifications()

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  start() {
    handleImport()
  },
})

const dialog = ref(false)
const isLoading = ref(false)

const hasExample = computed(() => props.example || props.headersExample)

const downloadTemplate = () => {
  if (props.example) {
    window.open(props.example, '_blank')
    return
  }

  const csvContent = props.headersExample
    ? `data:text/csv;charset=utf-8,${props.headersExample}`
    : ''
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  const fileName = `${snakeCase(props.title.toLowerCase())}_template.csv`
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

const handleDialogClose = () => {
  if (isLoading.value) return
  dialog.value = false
}

const handleImport = () => {
  if (hasExample.value) {
    dialog.value = true
  } else {
    processFile()
  }
}

const processFile = () => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = '.csv'
  const cols: Record<string, any> = {}
  props.columns?.forEach(col => {
    cols[col.name] = {
      isDate: col.filter?.isDate,
      name: col.name,
    }
  })
  input.addEventListener('change', () => {
    const file = input.files?.[0]
    if (!file || file.type !== 'text/csv') {
      error({
        message: 'Wrong file type',
      })
      return
    }
    emit('import:start')
    isLoading.value = true
    const result: ImportDialogItem[] = []
    let abortParse = false
    let index = 1
    parse(file, {
      header: true,
      delimiter: CSV_SEPARATOR,
      skipEmptyLines: true,
      transformHeader: props.columns?.length
        ? function (header: string): string {
            return cols[header]?.name ?? header
          }
        : undefined,
      beforeFirstChunk: function (chunk) {
        const rows = chunk.split(/\r\n|\r|\n/)
        const headings = rows[0].toLowerCase()
        if (
          props.headersExample &&
          !validateHeadings(headings, props.headersExample)
        ) {
          error({
            message: 'Wrong file format. Please download the template',
          })
          abortParse = true
          return
        }

        const dateIndex = headings.split(',').indexOf('date')
        if (dateIndex !== -1) {
          for (let i = 1; i < rows.length; i++) {
            const row = rows[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)
            const date = row?.[dateIndex]

            if (date && !/^\d{4}-\d{2}-\d{2}$/.test(date)) {
              error({
                message:
                  'Wrong date format in file. Make sure all dates are in ISO format (YYYY-MM-DD)',
              })
              abortParse = true
              return
            }
          }
        }

        rows[0] = headings
        return rows.join('\r\n')
      },
      step: ({ data, errors, meta }, parser) => {
        const dataIsEmpty = Object.values(data as object).every(value => !value)

        if (abortParse || dataIsEmpty) {
          abortParse && parser.abort()
          return
        }

        index++
        if (errors.length) {
          error({
            message: `Error in row ${index}: ${errors
              .map(err => err.message)
              .join(', ')} (it will not be included in the result)`,
          })
        } else if (meta.aborted || typeof data !== 'object') {
          error({
            message: `The row ${index} will not be included in the result`,
          })
        } else {
          for (const property in data as ImportDialogItem) {
            if ((data as ImportDialogItem)[property] instanceof Error) {
              error({
                message: `Wrong date format in file`,
                description: ((data as ImportDialogItem)[property] as Error)
                  .message,
              })
              parser.abort()
            }
          }

          emit('import:step', {
            data: data as ImportDialogItem,
            index,
            callback: (item: ImportDialogItem) => {
              result.push(item)
            },
          })
        }
      },
      complete: function () {
        isLoading.value = false
        dialog.value = false
        emit('import:complete', result)
      },
    })
  })
  input.classList.add('hidden')
  document.body.appendChild(input)
  input.click()
}
</script>

<script lang="ts">
export default {
  name: 'UIImportDialog',
}
</script>
