export const validateHeadings = (
  headings: string | string[],
  headingsExample: string | string[],
) => {
  if (typeof headings === 'string') {
    headings = headings.split(',')
  }
  if (typeof headingsExample === 'string') {
    headingsExample = headingsExample.split(',')
  }

  return headings.every(heading => headingsExample.includes(heading))
}
