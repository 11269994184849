<template>
  <div class="slide-panel-minimized">
    <CloseButton data-refid="slidePanelCloseMinimised" @click="handleClose" />
    <FoldButton class="slide-panel-minimized__fold" @click="handleUnminimize" />
    <div class="slide-panel-minimized__title">
      {{ title }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VNode } from 'vue'

import CloseButton from './components/CloseButton.vue'
import FoldButton from './components/FoldButton.vue'

type Props = {
  title?: string | VNode
}

type Emits = {
  'click:close': []
  'click:unminimize': []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleUnminimize = () => {
  emit('click:unminimize')
}

const handleClose = () => {
  emit('click:close')
}
</script>

<style scoped>
.slide-panel-minimized {
  @apply h-full w-full;
  @apply flex items-center;
  @apply gap-3;
  @apply shadow-xl dark:shadow-gray-800;

  .ui-slide-panel__container--horizontal & {
    @apply flex-col;
    @apply py-safe-offset-5;
  }

  .ui-slide-panel__container--vertical & {
    @apply px-5;
  }

  &__title {
    @apply flex items-center;
    @apply font-medium;

    .ui-slide-panel__container--horizontal & {
      @apply rotate-180;
      writing-mode: vertical-rl;
    }
  }

  &__fold {
    .ui-slide-panel__container--horizontal & {
      @apply rotate-180;
    }

    .ui-slide-panel__container--vertical & {
      @apply -rotate-90;
    }
  }
}
</style>
