<template>
  <Dialog
    v-bind="{
      icon,
      position,
      size,
      title,
      zIndex,
    }"
    :content-class="wrapperClass"
    @hide="handleHide"
  >
    <template #default>
      <slot />
    </template>
    <template #buttons>
      <UIButton
        v-for="action in actions"
        :key="action.label"
        :label="action.label"
        :variant="action.buttonVariant"
        :disabled="action.disabled"
        :loading="action.loading"
        @click="action.onClick"
      />
      <UIButton
        label="Cancel"
        variant="gray"
        fill="outlined"
        @click="handleHide"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { VNode } from 'vue'

import { DialogActionButton, DialogIcon, ModalSize } from './utils/types'

import { UIButton } from '@ui/buttons'
import Dialog from './Dialog.vue'

type Props = {
  title?: string | VNode
  size: ModalSize
  icon?: DialogIcon
  position: 'top' | 'center'
  actions?: DialogActionButton[]
  zIndex: number
  wrapperClass?: string
}
defineProps<Props>()

type Emits = {
  hide: []
}
const emit = defineEmits<Emits>()

const handleHide = () => {
  emit('hide')
}
</script>

<script lang="ts">
export default {
  name: 'DialogAction',
}
</script>
